/* Media queries */

@mixin phone {
  @media (min-width: #{$phone-width}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin widescreen {
  @media (min-width: #{$widescreen-width}) {
    @content;
  }
}

/* Center vertically and/or horizontally an absolute positioned element */

@mixin center($xy:xy) {
  @if $xy == xy {
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    // @include transform(translateX(-50%) translateY(-50%));
    transform: translateX(-50%) translateY(-50%);
  }
  @else if $xy == x {
    left: 50%;
    right: auto;
    // @include transform(translateX(-50%));
    transform: translateX(-50%);
  }
  @else if $xy == y {
    top: 50%;
    bottom: auto;
    // @include transform(translateY(-50%));
    transform: translateY(-50%);
  }
}

/* Antialiasing mode font rendering */

@mixin font-smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Button */

@mixin button($color, $type) {
  border-radius: $round;
  text-transform: lowercase;
  box-sizing: border-box;
  text-decoration: none;
  text-align: center;
  font-family: $primaryFont;
  font-weight: 700;
  cursor: pointer;

  @if $type == primary {
    border: $borderThickness solid $color;
    color: $backgroundColor;
    background-color: $color;

    &:hover {
      color: $color;
      background-color: transparent;
    }

  } @else if $type == secondary {
    border: $borderThickness solid $color;
    color: $color;

    &:hover {
      color: $backgroundColor;
      background-color: $color;
    }

  } @else if $type == tertiary {
    border: $borderThickness solid transparent;
    color: $color;

    &:hover {
      border-color: $color;
    }

  } @else {
    @error "Unknown button type #{$type}.";
  }

  padding: 0.5em 2.25em;

  font-size: 1rem;

  @include tablet {
  }
}

/* Transitions - use @include transition-quick(.5s); */

@mixin transition-quick($duration) {
    -webkit-transition: all $duration cubic-bezier(0.33,0.66,0.66,1);
    -moz-transition: all $duration cubic-bezier(0.33,0.66,0.66,1);
    -ms-transition: all $duration cubic-bezier(0.33,0.66,0.66,1);
    -o-transition: all $duration cubic-bezier(0.33,0.66,0.66,1);
    transition: all $duration cubic-bezier(0.33,0.66,0.66,1);
}

/* Scrollbars support for all browsers */

@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white,  50%)) {
  // For Google Chrome
  &::-webkit-scrollbar {
    width:  $size;
    height: $size;
    border-radius: 1em;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
    border-radius: 1em;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
    border-radius: $borderRadius;
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}
