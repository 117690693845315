.cards {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &--row-2 {

    @include desktop {
      // margin-top: -($spacing / 2);
      flex-direction: row;
      flex-wrap: wrap;

      .card {
        width: 48%;
      }
    }
  }

  &--row-3 {
    @include desktop {
      margin-top: -($spacing / 2);
      flex-direction: row;

      .card {
        width: 30%;
      }
    }
  }
}

.card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  padding: $spacing / 4;
  // border: $foregroundColor solid $borderThickness;
  border-radius: $borderRadius;
  margin-bottom: $spacing / 4;
  background: $backgroundGradient;
  color: inherit;
  text-decoration: none;
  list-style-type: none;
  transition: transform .2s; /* Animation */
  @include phone {
    padding: $spacing / 3;
  }

  &--clickable {

    &:hover {
      background: $primaryGradient;
      background-attachment: fixed;

      .card__link {
        // text-decoration: underline;
      }
    }
  }

  &:hover {
    transform: scale(1.05);

    .card__link {
      color: $foregroundColor;
    }
  }

  h2, h3, &__heading {
    word-wrap: break-word;
  }

  p, &__text {

  }

  ul {
    list-style: circle;
  }

  &__link {
    color: $primaryColor;
    text-decoration: none;
    font-style: italic;
    // align-self: flex-end;
  }

  &__button {
    margin: 0 auto;
  }
}
