@font-face {
  font-family: 'CircularStd';
  font-style: normal;
  font-weight: 500;
  src: url('/static/fonts/CircularStd-Book.woff2') format('woff2'), /* Super Modern Browsers */
       url('/static/fonts/CircularStd-Book.woff') format('woff'); /* Pretty Modern Browsers */
  font-display: swap;
}

// @font-face {
//   font-family: 'CircularStd';
//   font-style: normal;
//   font-weight: 600;
//   src: url('/static/fonts/CircularStd-Medium.woff2') format('woff2'), /* Super Modern Browsers */
//        url('/static/fonts/CircularStd-Medium.woff') format('woff'); /* Pretty Modern Browsers */
//     font-display: swap;
// }

@font-face {
  font-family: 'CircularStd';
  font-style: normal;
  font-weight: 700;
  src: url('/static/fonts/CircularStd-Bold.woff2') format('woff2'), /* Super Modern Browsers */
       url('/static/fonts/CircularStd-Bold.woff') format('woff'); /* Pretty Modern Browsers */
  font-display: swap;
}

// @font-face {
//   font-family: 'CircularStd';
//   font-style: normal;
//   font-weight: 900;
//   src: url('/static/fonts/CircularStd-Black.woff2') format('woff2'), /* Super Modern Browsers */
//        url('/static/fonts/CircularStd-Black.woff') format('woff'); /* Pretty Modern Browsers */
//   font-display: swap;
// }
