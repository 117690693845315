.taglist {
  margin-bottom: $spacing / 2 !important;

   h2 {
     text-align: center;
     margin-bottom: .5em;
   }
}

.tags {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;

  &--large {
    justify-content: center;
    margin-bottom: 2.5rem;

    .tag {
      margin: .5rem .25rem;

      &[data-current="current-item"] {
        margin: .5rem .5rem;
      }

      a {
        padding: .5rem 1rem;
        font-size: .85rem;
        border-radius: $borderRadiusSmall;

        @include tablet {
          padding: .7rem 1.4rem;
        }
      }
    }
  }
}

.tag {
  text-transform: lowercase;
  margin: 0 .5rem .5rem 0;

  &[data-current="current-item"] a {
    color: $primaryColor;
    font-weight: 700;
    background: $darkGradient;
    transform: scale(1.08);
  }

  a {
    border-radius: $round;
    display: inline-block;
    padding: 0.25rem 0.75rem;
    background: $backgroundGradient;
    color: $foregroundColor;
    text-decoration: none;
    transition: all .2s ease-in-out;
    font-size: .85rem;

    &:hover {
      color: $primaryColor;
    }

    @include tablet {
    }
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}
