@charset "UTF-8";
@font-face {
  font-family: 'CircularStd';
  font-style: normal;
  font-weight: 500;
  src: url("/static/fonts/CircularStd-Book.woff2") format("woff2"), url("/static/fonts/CircularStd-Book.woff") format("woff");
  /* Pretty Modern Browsers */
  font-display: swap; }

@font-face {
  font-family: 'CircularStd';
  font-style: normal;
  font-weight: 700;
  src: url("/static/fonts/CircularStd-Bold.woff2") format("woff2"), url("/static/fonts/CircularStd-Bold.woff") format("woff");
  /* Pretty Modern Browsers */
  font-display: swap; }

/* Breakpoints */
/* Media queries */
/* Center vertically and/or horizontally an absolute positioned element */
/* Antialiasing mode font rendering */
/* Button */
/* Transitions - use @include transition-quick(.5s); */
/* Scrollbars support for all browsers */
/*base code*/
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }
  .animated.infinite {
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite; }
  .animated.hinge {
    -webkit-animation-duration: 2s;
    animation-duration: 2s; }

/* Fade - In - Right */
@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    -ms-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none; } }

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight; }

/* Fade - Out - Right */
@-webkit-keyframes fadeOutRight {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); } }

@keyframes fadeOutRight {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    -ms-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); } }

.fadeOutRight {
  -webkit-animation-name: fadeOutRight;
  animation-name: fadeOutRight; }

/* Zoom  - In */
@-webkit-keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  50% {
    opacity: 1; } }

@keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    -ms-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  50% {
    opacity: 1; } }

.zoomIn {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn; }

/* Zoom  - Out */
@-webkit-keyframes zoomOut {
  0% {
    opacity: 1; }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  100% {
    opacity: 0; } }

@keyframes zoomOut {
  0% {
    opacity: 1; }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    -ms-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  100% {
    opacity: 0; } }

.zoomOut {
  -webkit-animation-name: zoomOut;
  animation-name: zoomOut; }

html {
  font-size: calc(20px + 2 * ((100vw - 960px) / 680));
  font-family: "CircularStd", sans-serif;
  font-variant-ligatures: no-common-ligatures;
  overflow-wrap: break-word;
  word-break: break-word; }
  @media (min-width: 481px) {
    html {
      overflow-wrap: normal;
      word-break: normal; } }

@media screen and (min-width: 2500px) {
  html {
    font-size: 22px; } }

h1,
h2,
h3,
h4,
h5 {
  margin: 0 0 1em 0;
  font-weight: 700;
  text-transform: lowercase;
  line-height: 1.15; }

h1 {
  font-size: calc(1.802rem + 10 * ((100vw - 640px) / 480));
  margin-bottom: 1rem; }

h2 {
  font-size: calc(1.602rem + 8 * ((100vw - 640px) / 480)); }

h3 {
  font-size: calc(1.424rem + 4 * ((100vw - 640px) / 480)); }

h4 {
  font-size: calc(1.266rem + 1 * ((100vw - 640px) / 480)); }

h5 {
  font-size: calc(1.125rem + 0 * ((100vw - 640px) / 480)); }

p {
  font-weight: 500;
  line-height: 1.5;
  margin: 0 0 1em 0; }

code,
small,
.small {
  font-size: 0.85rem;
  font-weight: 500;
  line-height: 1.75; }

@media (min-width: 760px) {
  .break {
    display: none; } }

.checkbox {
  display: flex;
  cursor: pointer;
  position: relative;
  align-items: center; }

.checkbox > input {
  height: 36px;
  width: 36px;
  margin: 0 1em 0 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 3px solid #fff;
  border-radius: 10px;
  outline: none;
  transition-duration: 0.3s;
  cursor: pointer; }

.checkbox > input:checked + ::before {
  content: '\2713';
  display: block;
  font-size: 24px;
  text-align: center;
  color: #fff;
  position: absolute;
  left: 6px;
  top: 6px; }

.checkbox > input:active {
  border: 3px solid #00ffcd; }

.pagehero {
  position: relative;
  text-align: center;
  padding: 15vmax 0;
  display: flex;
  overflow: hidden; }
  .pagehero:before {
    content: " ";
    position: absolute;
    max-width: 100%;
    max-height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -10;
    background: #171717; }
  @media (min-width: 760px) {
    .pagehero {
      text-align: left;
      padding: 25vh 0; } }
  .pagehero__header {
    z-index: 10; }
    @media (min-width: 760px) {
      .pagehero__header {
        width: 60vmax;
        position: relative; } }
  .pagehero__tagline {
    margin-bottom: 1em;
    font-size: 1.25rem;
    line-height: 1.818rem;
    font-weight: 700;
    background: linear-gradient(90deg, #00FFCD 0.29%, #2A03FF 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent; }
    .pagehero__tagline:before {
      content: "—";
      margin-right: 0.25em; }
  .pagehero__description a {
    color: #fff; }
  .pagehero__ctas {
    display: flex;
    flex-direction: column;
    margin: 1em 0 0;
    padding: 0;
    list-style-type: none; }
    @media (min-width: 760px) {
      .pagehero__ctas {
        flex-direction: row; } }
    .pagehero__ctas li {
      margin: 0 0 1em 0; }
      @media (min-width: 760px) {
        .pagehero__ctas li {
          margin: 0 1em 0 0; } }
  .pagehero__cta {
    display: block; }
    .pagehero__cta--primary {
      border-radius: 9999px;
      text-transform: lowercase;
      box-sizing: border-box;
      text-decoration: none;
      text-align: center;
      font-family: "CircularStd";
      font-weight: 700;
      cursor: pointer;
      border: 3px solid #00ffcd;
      color: #0D0D0D;
      background-color: #00ffcd;
      padding: 0.5em 2.25em;
      font-size: 1rem; }
      .pagehero__cta--primary:hover {
        color: #00ffcd;
        background-color: transparent; }
    .pagehero__cta--secondary {
      border-radius: 9999px;
      text-transform: lowercase;
      box-sizing: border-box;
      text-decoration: none;
      text-align: center;
      font-family: "CircularStd";
      font-weight: 700;
      cursor: pointer;
      border: 3px solid #00ffcd;
      color: #00ffcd;
      padding: 0.5em 2.25em;
      font-size: 1rem; }
      .pagehero__cta--secondary:hover {
        color: #0D0D0D;
        background-color: #00ffcd; }
  .pagehero__image {
    display: none; }
    @media (min-width: 992px) {
      .pagehero__image {
        display: flex;
        justify-content: center;
        width: 40%;
        padding: 0 3rem;
        box-sizing: border-box; } }
    .pagehero__image img {
      width: 100%; }
  .pagehero__separator {
    position: absolute;
    width: calc(100% + 10px);
    height: 100%;
    left: -5px;
    right: -5px;
    bottom: -2px;
    z-index: 5;
    background: url("/static/img/wave-2.svg") no-repeat center bottom;
    background-size: contain; }

#effect {
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -5;
  background: #171717; }

.posthero {
  position: relative;
  text-align: center;
  padding: 25vh 0;
  overflow: hidden; }
  @media (min-width: 760px) {
    .posthero {
      text-align: left; } }
  .posthero__image {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -2; }
  @media (min-width: 760px) {
    .posthero__header {
      max-width: 60vmax;
      z-index: 11; } }
  .posthero__postmeta {
    margin-bottom: .5em;
    font-weight: 700;
    line-height: 1.818rem; }
    .posthero__postmeta:before {
      content: "—";
      margin-right: 0.25em; }
  .posthero__summary {
    font-style: italic; }
    .posthero__summary a {
      color: #fff; }
  .posthero__separator {
    position: absolute;
    width: calc(100% + 10px);
    height: 100%;
    left: -5px;
    right: -5px;
    bottom: -2px;
    z-index: -1;
    background: url("/static/img/wave-1.svg") no-repeat center bottom;
    background-size: contain; }
  .posthero__tags {
    justify-content: center;
    margin-bottom: 12em; }
    @media (min-width: 760px) {
      .posthero__tags {
        justify-content: flex-start; } }

.header {
  position: fixed;
  left: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 80px;
  z-index: 999; }
  @media (min-width: 992px) {
    .header {
      height: 120px; } }
  .header__logo {
    height: 40px;
    margin-right: auto;
    text-decoration: none; }
    .header__logo:hover img {
      transform: scale(-1, 1); }
    .header__logo img {
      height: 100%;
      transition: transform .5s ease-in-out; }
  .header__navigation {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    background-color: #00ffcd;
    overflow-y: auto; }
    @media (min-width: 992px) {
      .header__navigation {
        display: block;
        position: static;
        left: auto;
        top: auto;
        right: auto;
        bottom: auto;
        height: auto;
        background: none;
        overflow-y: visible; } }
    .header__navigation.open {
      display: block; }
  .header__links {
    font-size: 1.2rem;
    list-style-type: none;
    margin: 0;
    height: 100vh;
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 99;
    padding: 0;
    /* this fixes the buggy scrolling on webkit browsers - mobile devices only - when overflow property is applied */ }
    @media (min-width: 992px) {
      .header__links {
        font-size: 1rem;
        flex-wrap: nowrap;
        flex-direction: row;
        align-items: center;
        height: auto; } }
  @media (min-width: 992px) {
    .header__link:first-child {
      display: none; } }
  .header__link a {
    padding: 1.5em;
    display: block;
    color: #0D0D0D;
    text-decoration: none;
    text-transform: lowercase; }
    @media (min-width: 992px) {
      .header__link a {
        padding: 0 1em;
        margin-left: 1em;
        color: #fff; }
        .header__link a:hover {
          text-decoration: underline; } }
  .header__cta {
    margin: 2.5em 0 0 0; }
    .header__cta a {
      border-radius: 9999px;
      text-transform: lowercase;
      box-sizing: border-box;
      text-decoration: none;
      text-align: center;
      font-family: "CircularStd";
      font-weight: 700;
      cursor: pointer;
      border: 3px solid #0D0D0D;
      color: #0D0D0D;
      padding: 0.5em 2.25em;
      font-size: 1rem; }
      .header__cta a:hover {
        color: #0D0D0D;
        background-color: #0D0D0D; }
    @media (min-width: 992px) {
      .header__cta {
        margin: 0 0 0 1em; }
        .header__cta a {
          border-radius: 9999px;
          text-transform: lowercase;
          box-sizing: border-box;
          text-decoration: none;
          text-align: center;
          font-family: "CircularStd";
          font-weight: 700;
          cursor: pointer;
          border: 3px solid #fff;
          color: #0D0D0D;
          background-color: #fff;
          padding: 0.5em 2.25em;
          font-size: 1rem; }
          .header__cta a:hover {
            color: #fff;
            background-color: transparent; } }
  .header__trigger {
    height: 60px;
    width: 60px;
    z-index: 999;
    overflow: hidden;
    border-radius: 9999px; }
    @media (min-width: 992px) {
      .header__trigger {
        display: none; } }
    .header__trigger .trigger-icon {
      width: 40px;
      height: 20px;
      position: relative;
      margin: 20px auto;
      transform: rotate(0deg);
      transition: .5s ease-in-out;
      cursor: pointer; }
      .header__trigger .trigger-icon span {
        display: block;
        position: absolute;
        height: 4px;
        width: 100%;
        background: #fff;
        border-radius: 9999px;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: .25s ease-in-out; }
        .header__trigger .trigger-icon span:nth-child(1) {
          top: 0px; }
        .header__trigger .trigger-icon span:nth-child(2), .header__trigger .trigger-icon span:nth-child(3) {
          opacity: 0;
          top: 8px; }
        .header__trigger .trigger-icon span:nth-child(4) {
          top: 16px; }
    .header__trigger.open {
      background: #0D0D0D; }
      .header__trigger.open span {
        background: #00ffcd; }
      .header__trigger.open span:nth-child(1) {
        top: 8px;
        width: 0%;
        left: 50%; }
      .header__trigger.open span:nth-child(2) {
        transform: rotate(45deg);
        opacity: 1; }
      .header__trigger.open span:nth-child(3) {
        transform: rotate(-45deg);
        opacity: 1; }
      .header__trigger.open span:nth-child(4) {
        top: 8px;
        width: 0%;
        left: 50%; }

/* Headroom-specific CSS */
.headroom {
  will-change: transform;
  transition: transform 200ms linear, background-color 200ms ease-in; }
  .headroom--pinned {
    transform: translateY(0%); }
  .headroom--unpinned {
    transform: translateY(-100%); }
  .headroom--top {
    background-color: rgba(13, 13, 13, 0); }
  .headroom--not-top {
    background-color: rgba(13, 13, 13, 0.925);
    backdrop-filter: blur(0.75em); }

.postcards {
  display: grid;
  grid-template-columns: repeat(auto-fit, 1fr);
  grid-gap: 1.5rem; }
  @media (min-width: 1201px) {
    .postcards {
      grid-gap: 3rem; } }
  @media (min-width: 992px) {
    .postcards {
      grid-gap: 2rem; } }
  @media (min-width: 760px) {
    .postcards {
      grid-template-columns: repeat(auto-fill, minmax(30ch, 1fr)); } }

.postcard {
  box-sizing: border-box;
  border-radius: 1rem;
  text-decoration: none;
  background: linear-gradient(142.28deg, #171717 0%, #0D0D0D 100%);
  overflow: hidden;
  transition: transform .2s;
  /* Animation */ }
  .postcard:hover {
    transform: scale(1.05); }
  .postcard__description {
    padding: 1.4em 1.6em;
    color: #fff; }
  .postcard__thumbnail {
    display: block;
    width: 100%;
    position: relative;
    height: 200px;
    overflow: hidden;
    background: #171717; }
    .postcard__thumbnail img {
      min-width: 100%;
      max-height: 100%;
      object-fit: cover; }
  .postcard__heading {
    margin-bottom: .4em;
    font-size: calc(1.424rem + 1.5 * ((100vw - 960px) / 480)); }
    .postcard__heading a {
      display: block;
      color: #fff;
      word-wrap: break-word;
      text-decoration: none; }
  .postcard__summary {
    display: none; }
    @media (min-width: 481px) {
      .postcard__summary {
        display: block; } }
  .postcard__meta {
    margin-bottom: .4em; }
    .postcard__meta time, .postcard__meta span {
      color: gray; }
    .postcard__meta a {
      color: #00ffcd;
      text-decoration: none; }
  .postcard__tags {
    justify-content: flex-start; }

.post {
  margin-top: 6rem;
  margin-bottom: 6rem; }
  @media (min-width: 992px) {
    .post {
      margin-top: 3rem; } }
  .post h2:not(:first-child) {
    margin-top: 6rem; }
  .post h3,
  .post h4,
  .post h5,
  .post h6 {
    margin-top: 3rem; }
  .post h2 .header-anchor,
  .post h3 .header-anchor,
  .post h4 .header-anchor,
  .post h5 .header-anchor,
  .post h6 .header-anchor {
    position: absolute;
    font-size: 0.75em;
    padding-left: 0.5em;
    text-decoration: none;
    display: none; }
  .post h2:hover .header-anchor,
  .post h3:hover .header-anchor,
  .post h4:hover .header-anchor,
  .post h5:hover .header-anchor,
  .post h6:hover .header-anchor {
    display: inline;
    color: inherit;
    background: inherit; }
  .post ul,
  .post ol {
    padding: 0 0 0.5em 3rem;
    list-style-position: outside; }
    .post ul li,
    .post ol li {
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      margin: 0.5em 0 0.5em 0; }
  .post a {
    color: #408cfc;
    text-decoration: none;
    -webkit-transition: all 0.3s cubic-bezier(0.33, 0.66, 0.66, 1);
    -moz-transition: all 0.3s cubic-bezier(0.33, 0.66, 0.66, 1);
    -ms-transition: all 0.3s cubic-bezier(0.33, 0.66, 0.66, 1);
    -o-transition: all 0.3s cubic-bezier(0.33, 0.66, 0.66, 1);
    transition: all 0.3s cubic-bezier(0.33, 0.66, 0.66, 1); }
    .post a:hover {
      color: #fff;
      background: #004CBD; }
  .post blockquote {
    font-style: italic;
    margin: 1.2em 0;
    padding: 0 1em 0 2em;
    display: flex;
    flex-direction: column;
    position: relative;
    font-size: 1.1rem; }
    .post blockquote:before {
      position: absolute;
      transform: translate(-2em, -0.5em);
      border-radius: .3em;
      width: .6em;
      height: 100%;
      white-space: pre;
      content: " ";
      background: #00ffcd; }
  .post code {
    background: #171717;
    padding: 0 .5em;
    border-radius: 0.3rem; }
  .post pre {
    display: block;
    background: #171717;
    border-radius: 0.3rem; }
    .post pre code {
      white-space: pre;
      padding: 1em;
      display: block;
      overflow-x: scroll;
      max-width: 100%; }
      .post pre code::-webkit-scrollbar {
        width: 0.6em;
        height: 0.6em;
        border-radius: 1em; }
      .post pre code::-webkit-scrollbar-thumb {
        background: #363636;
        border-radius: 1em; }
      .post pre code::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 1rem; }
      .post pre code {
        scrollbar-face-color: #363636;
        scrollbar-track-color: transparent; }
  .post figure {
    background: linear-gradient(142.28deg, #171717 0%, #0D0D0D 100%);
    margin-left: 0;
    margin-right: 0;
    margin-top: 3rem;
    margin-bottom: 3rem;
    width: 100%;
    border-radius: 1rem; }
    @media (min-width: 760px) {
      .post figure {
        width: calc(100% + 2rem);
        margin-left: -1rem;
        margin-right: -1rem; } }
    .post figure img {
      width: 100%;
      height: auto;
      border-radius: 1rem 1rem 0 0;
      padding: 0; }
    .post figure figcaption {
      padding: 1.4em 1.4em;
      font-size: .9rem; }

.cards {
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  @media (min-width: 992px) {
    .cards--row-2 {
      flex-direction: row;
      flex-wrap: wrap; }
      .cards--row-2 .card {
        width: 48%; } }
  @media (min-width: 992px) {
    .cards--row-3 {
      margin-top: -3rem;
      flex-direction: row; }
      .cards--row-3 .card {
        width: 30%; } }

.card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  padding: 1.5rem;
  border-radius: 1rem;
  margin-bottom: 1.5rem;
  background: linear-gradient(142.28deg, #171717 0%, #0D0D0D 100%);
  color: inherit;
  text-decoration: none;
  list-style-type: none;
  transition: transform .2s;
  /* Animation */ }
  @media (min-width: 481px) {
    .card {
      padding: 2rem; } }
  .card--clickable:hover {
    background: linear-gradient(90deg, #00FFCD 0.29%, #2A03FF 100%);
    background-attachment: fixed; }
  .card:hover {
    transform: scale(1.05); }
    .card:hover .card__link {
      color: #fff; }
  .card h2, .card h3, .card__heading {
    word-wrap: break-word; }
  .card ul {
    list-style: circle; }
  .card__link {
    color: #00ffcd;
    text-decoration: none;
    font-style: italic; }
  .card__button {
    margin: 0 auto; }

.accordion {
  margin-bottom: 6rem; }
  .accordion__list {
    margin: 0;
    padding: 0;
    list-style: none; }

.accordion-item {
  margin-bottom: 1.75em;
  transition: height 1s ease-in-out; }
  .accordion-item__heading {
    cursor: pointer;
    transition: padding 0.15s ease-in-out; }
    .accordion-item__heading:before {
      margin-right: 1em;
      content: "+"; }
    .accordion-item__heading:hover, .accordion-item__heading:active {
      padding-left: 1em; }
  .accordion-item__content {
    display: none;
    margin-bottom: 2.5em; }
  .accordion-item.open .accordion-item__heading:before {
    content: "—"; }
  .accordion-item.open .accordion-item__content {
    display: block; }

.postcontrols {
  display: grid;
  grid-gap: 1rem;
  margin-top: 6rem; }
  @media (min-width: 760px) {
    .postcontrols {
      grid-template-columns: 1fr 1fr;
      justify-content: center;
      align-content: center; } }
  .postcontrols h3 {
    margin-bottom: 0; }
  .postcontrols__control {
    color: #00ffcd; }

.paragraph {
  margin-bottom: 6rem; }
  @media (min-width: 760px) {
    .paragraph {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row-reverse;
      text-align: left; }
      .paragraph--reverse {
        flex-direction: row;
        text-align: right; } }
  .paragraph__content {
    box-sizing: border-box; }
    @media (min-width: 760px) {
      .paragraph__content {
        max-width: 40%; } }
  .paragraph__image {
    padding: 2em;
    margin-bottom: 3rem; }
    @media (min-width: 760px) {
      .paragraph__image {
        max-width: 45%;
        padding: 0;
        margin-bottom: 0; } }
    .paragraph__image img {
      width: 100%; }

.focused {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 12rem auto;
  text-align: center; }
  .focused--small {
    margin-top: 0; }
  @media (min-width: 992px) {
    .focused {
      margin: 9rem 12rem; }
      .focused--small {
        margin-top: 0; } }
  .focused__text {
    margin-bottom: 3rem; }
  .focused__image {
    padding: 2em;
    margin-bottom: 3rem; }
    @media (min-width: 760px) {
      .focused__image {
        max-width: 60%;
        padding: 0; } }
    .focused__image img {
      width: 100%; }

.services {
  display: grid;
  grid-gap: 1rem; }
  @media (min-width: 992px) {
    .services {
      grid-template-columns: repeat(5, 1fr); } }

.service {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2em;
  min-height: 12em;
  text-decoration: none;
  color: #fff;
  border-radius: 1rem;
  background: linear-gradient(142.28deg, #171717 0%, #0D0D0D 100%); }
  .service:hover {
    background: linear-gradient(90deg, #00FFCD 0.29%, #2A03FF 100%);
    background-attachment: fixed; }
  @media (min-width: 992px) {
    .service:nth-child(1) {
      grid-area: 1 / 1 / 2 / 4; }
    .service:nth-child(2) {
      grid-area: 1 / 4 / 2 / 6; }
    .service:nth-child(3) {
      grid-area: 2 / 1 / 3 / 3; }
    .service:nth-child(4) {
      grid-area: 2 / 3 / 3 / 6; } }
  .service__icon {
    align-self: flex-end;
    height: 3em;
    width: 3em;
    color: #00ffcd; }
    .service__icon img {
      width: 100%; }

.cta {
  margin: 12rem auto;
  text-align: center; }
  @media (min-width: 760px) {
    .cta {
      margin: 9rem auto; } }
  .cta__image {
    margin: 0 auto 2em; }
    @media (min-width: 760px) {
      .cta__image {
        width: 60%; } }
    @media (min-width: 992px) {
      .cta__image {
        width: 50%; } }
    .cta__image img {
      max-height: 80vh;
      width: 100%; }
  .cta__title {
    margin-bottom: 0; }
  .cta__text {
    margin: .5em 10% 0; }
  .cta__action {
    margin: 1.5em auto 0; }
  .cta__field {
    width: 100%;
    max-width: 100%;
    border-radius: 9999px;
    border: 3px solid #fff;
    background: #fff;
    padding: 0.5em 0.5em;
    font-size: 1rem;
    font-weight: 700;
    font-family: "CircularStd";
    margin: .25em 0;
    color: #0D0D0D;
    text-align: center;
    box-sizing: border-box; }
    @media (min-width: 760px) {
      .cta__field {
        padding: 0.5em 2.25em;
        width: auto; } }
  .cta__button {
    border-radius: 9999px;
    text-transform: lowercase;
    box-sizing: border-box;
    text-decoration: none;
    text-align: center;
    font-family: "CircularStd";
    font-weight: 700;
    cursor: pointer;
    border: 3px solid #00ffcd;
    color: #0D0D0D;
    background-color: #00ffcd;
    padding: 0.5em 2.25em;
    font-size: 1rem;
    font-family: "CircularStd";
    margin: .25em; }
    .cta__button:hover {
      color: #00ffcd;
      background-color: transparent; }
  .cta__subtext {
    display: block;
    margin: 2em 10% 0; }
    @media (min-width: 760px) {
      .cta__subtext {
        margin: 1.5em 25% 0;
        font-size: .8em; } }
    .cta__subtext a {
      color: #fff; }

.taglist {
  margin-bottom: 3rem !important; }
  .taglist h2 {
    text-align: center;
    margin-bottom: .5em; }

.tags {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap; }
  .tags--large {
    justify-content: center;
    margin-bottom: 2.5rem; }
    .tags--large .tag {
      margin: .5rem .25rem; }
      .tags--large .tag[data-current="current-item"] {
        margin: .5rem .5rem; }
      .tags--large .tag a {
        padding: .5rem 1rem;
        font-size: .85rem;
        border-radius: 0.3rem; }
        @media (min-width: 760px) {
          .tags--large .tag a {
            padding: .7rem 1.4rem; } }

.tag {
  text-transform: lowercase;
  margin: 0 .5rem .5rem 0; }
  .tag[data-current="current-item"] a {
    color: #00ffcd;
    font-weight: 700;
    background: linear-gradient(149.12deg, #242424 0%, #171717 100%);
    transform: scale(1.08); }
  .tag a {
    border-radius: 9999px;
    display: inline-block;
    padding: 0.25rem 0.75rem;
    background: linear-gradient(142.28deg, #171717 0%, #0D0D0D 100%);
    color: #fff;
    text-decoration: none;
    transition: all .2s ease-in-out;
    font-size: .85rem; }
    .tag a:hover {
      color: #00ffcd; }
  .tag:first-child {
    margin-left: 0; }
  .tag:last-child {
    margin-right: 0; }

.sm-block {
  padding: 5rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url("/static/img/blob.svg") center no-repeat;
  background-size: contain; }
  .sm-block__title {
    color: #0D0D0D;
    margin-bottom: .5rem; }
  .sm-block__icons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; }
  .sm-block__item {
    display: flex;
    margin: .4rem;
    padding: .5rem;
    text-decoration: none;
    background: #0D0D0D;
    border-radius: 9999px; }
    .sm-block__item:hover .sm-block__img {
      transform: scale(-1, 1); }
  .sm-block__img {
    width: 1.25rem;
    height: 1.25rem;
    transition: transform .5s ease-in-out; }

.footer {
  padding: 0 1em 8em;
  text-align: center;
  font-size: 1.2em; }
  @media (min-width: 992px) {
    .footer {
      padding: 6rem 6rem 4em; } }
  .footer__blocks {
    display: flex;
    flex-direction: column-reverse; }
    @media (min-width: 992px) {
      .footer__blocks {
        flex-direction: row;
        align-items: center;
        justify-content: flex-start; } }
  .footer__block {
    margin-bottom: 2em; }
    @media (min-width: 992px) {
      .footer__block {
        margin-bottom: 0; } }
  .footer__menu {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    list-style-type: none;
    font-weight: 600; }
    @media (min-width: 992px) {
      .footer__menu {
        text-align: left; } }
  .footer--column {
    margin: 2.5em 0; }
    @media (min-width: 992px) {
      .footer--column {
        margin: 0 0 0 5%; } }
  .footer__item {
    margin: .5em 0;
    text-transform: lowercase; }
    @media (min-width: 992px) {
      .footer__item {
        margin: .25em 0; } }
  .footer__link {
    margin: 0.5em 0;
    text-decoration: none;
    color: #fff; }
    .footer__link:hover {
      text-decoration: underline; }

.newsletter {
  margin-left: auto;
  align-self: flex-start; }
  @media (min-width: 992px) {
    .newsletter {
      width: 35%;
      text-align: right; } }
  .newsletter__title {
    margin-bottom: 1.25rem; }
  .newsletter__form {
    display: flex;
    position: relative;
    margin-bottom: .5rem;
    padding: 3px;
    border: 3px #fff solid;
    border-radius: 9999px;
    box-sizing: border-box; }
  .newsletter__group {
    position: relative;
    width: 100%; }
  .newsletter__label {
    position: absolute;
    text-transform: lowercase;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: .2s;
    text-align: left;
    z-index: -1;
    padding: .5em 5em .5em 1.5em;
    font-size: .75em; }
  .newsletter__field {
    width: 100%;
    border-radius: 9999px;
    border: none;
    padding: .5em 5em .5em 1.5em;
    font-size: .75em;
    font-weight: 700;
    font-family: "CircularStd";
    color: #fff;
    background: transparent;
    box-sizing: border-box; }
    .newsletter__field::placeholder {
      opacity: 0;
      transition: inherit; }
    .newsletter__field:focus + .newsletter__label {
      opacity: 0; }
    .newsletter__field:focus::placeholder {
      opacity: .5; }
    .newsletter__field:valid {
      background: #0D0D0D; }
  .newsletter__button {
    position: absolute;
    top: 3px;
    right: 3px;
    border-radius: 9999px;
    text-transform: lowercase;
    box-sizing: border-box;
    text-decoration: none;
    text-align: center;
    font-family: "CircularStd";
    font-weight: 700;
    cursor: pointer;
    border: 3px solid #00ffcd;
    color: #0D0D0D;
    background-color: #00ffcd;
    padding: 0.5em 2.25em;
    font-size: 1rem;
    padding-left: 1.5em;
    padding-right: 1.5em;
    padding-top: calc(.5em - 3px);
    padding-bottom: calc(.5em - 3px);
    font-size: .75em; }
    .newsletter__button:hover {
      color: #00ffcd;
      background-color: transparent; }
  .newsletter__description {
    font-size: .75rem;
    color: #363636;
    font-weight: 700; }
    .newsletter__description > a {
      color: #363636; }

.legal {
  margin-top: 5rem;
  color: #363636; }
  @media (min-width: 992px) {
    .legal {
      flex-direction: row;
      justify-content: flex-end; }
      .legal .footer__link {
        margin-left: 1em; } }
  .legal .footer__link {
    color: #363636; }

.modal {
  display: none; }
  .modal.is-open {
    display: block; }
  .modal__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(13, 13, 13, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(0.75em);
    z-index: 9999; }
    .modal__overlay:active, .modal__overlay:focus {
      outline: 0; }
    @supports not (backdrop-filter: blur(0.5em)) {
      .modal__overlay {
        background: rgba(13, 13, 13, 0.975); } }
  .modal__container {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 6rem;
    overflow-y: auto; }
  .modal__close {
    position: absolute;
    top: 7rem;
    right: 1.5rem;
    color: #fff;
    font-weight: 900;
    text-decoration: none;
    font-size: 3em; }
    @media (min-width: 481px) {
      .modal__close {
        right: 2rem; } }
    @media (min-width: 760px) {
      .modal__close {
        right: 3rem; } }
    @media (min-width: 992px) {
      .modal__close {
        top: 2.5em;
        right: 4rem; } }
    @media (min-width: 1201px) {
      .modal__close {
        right: 3rem; } }
    .modal__close:after {
      content: "+";
      display: block;
      transform: rotateZ(45deg); }
  .modal__content {
    overflow-y: auto;
    padding-top: 4rem;
    padding-bottom: 4rem; }
    @media (min-width: 481px) {
      .modal__content {
        padding-top: 3rem;
        padding-bottom: 3rem; } }
    @media (min-width: 992px) {
      .modal__content {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        grid-auto-rows: 1fr;
        padding-top: 6rem;
        padding-bottom: 6rem; } }

.contact-title {
  margin-bottom: 1em; }
  @media (min-width: 992px) {
    .contact-title {
      grid-area: 1 / 2 / 2 / 3;
      text-align: right;
      margin-bottom: 0;
      padding-left: 3rem; } }
  .contact-title h2 {
    margin-bottom: 1em; }
  @media (min-width: 992px) {
    .contact-title p {
      margin-left: 2em; } }

.form-panel {
  position: relative; }
  @media (min-width: 992px) {
    .form-panel {
      grid-area: 1 / 1 / 3 / 2;
      border-right: 2px solid #fff;
      padding-right: 3rem; } }

.contact-form label {
  display: block;
  width: 100%;
  margin-bottom: 0.25em;
  text-transform: lowercase; }
  @media (min-width: 992px) {
    .contact-form label {
      font-size: 1.25em; } }
  .contact-form label.checkbox-label {
    display: inline;
    text-transform: none; }
    @media (min-width: 992px) {
      .contact-form label.checkbox-label {
        font-size: 1em; } }

.contact-form .form-group {
  margin-bottom: 1em; }
  .contact-form .form-group.hidden {
    display: none; }

.contact-form .field {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border: 2px #fff solid;
  border-radius: 9999px;
  background: transparent;
  padding: 1em 2em;
  color: #fff;
  margin-bottom: 1em;
  font-family: 'Arial'; }
  @media (min-width: 992px) {
    .contact-form .field {
      font-size: .75em; } }
  .contact-form .field:active, .contact-form .field:focus {
    outline: 0; }

.contact-form .message {
  height: 10em;
  border-radius: 1rem;
  resize: vertical; }

.contact-form .form-check-input {
  margin-bottom: 1.5em; }

.contact-form .checkbox-label {
  cursor: pointer;
  margin-bottom: 0; }
  .contact-form .checkbox-label a {
    color: #fff; }

.contact-form #submit {
  border-radius: 9999px;
  text-transform: lowercase;
  box-sizing: border-box;
  text-decoration: none;
  text-align: center;
  font-family: "CircularStd";
  font-weight: 700;
  cursor: pointer;
  border: 3px solid #fff;
  color: #0D0D0D;
  background-color: #fff;
  padding: 0.5em 2.25em;
  font-size: 1rem; }
  .contact-form #submit:hover {
    color: #fff;
    background-color: transparent; }

.contact-data {
  margin-top: 5em; }
  @media (min-width: 992px) {
    .contact-data {
      margin-top: 0;
      grid-area: 2 / 2 / 3 / 3;
      display: flex;
      justify-content: flex-end; } }

.submit-message {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center; }
  @media (min-width: 992px) {
    .submit-message {
      padding-right: 3rem; } }

/* Breakpoints */
.klaro .cookie-modal,
.klaro .cookie-notice {
  /* The switch - the box around the slider */
  font-size: 16px; }
  .klaro .cookie-modal .switch,
  .klaro .cookie-notice .switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 30px; }
  .klaro .cookie-modal .cm-list-input:checked + .cm-list-label .slider,
  .klaro .cookie-notice .cm-list-input:checked + .cm-list-label .slider {
    background-color: #00ffcd; }
  .klaro .cookie-modal .cm-list-input.half-checked:checked + .cm-list-label .slider,
  .klaro .cookie-notice .cm-list-input.half-checked:checked + .cm-list-label .slider {
    opacity: 0.6;
    background-color: #00ffcd; }
    .klaro .cookie-modal .cm-list-input.half-checked:checked + .cm-list-label .slider::before,
    .klaro .cookie-notice .cm-list-input.half-checked:checked + .cm-list-label .slider::before {
      background-color: #171717;
      -webkit-transform: translateX(10px);
      -ms-transform: translateX(10px);
      transform: translateX(10px); }
  .klaro .cookie-modal .cm-list-input.only-required + .cm-list-label .slider,
  .klaro .cookie-notice .cm-list-input.only-required + .cm-list-label .slider {
    opacity: 0.8;
    background-color: #408cfc; }
    .klaro .cookie-modal .cm-list-input.only-required + .cm-list-label .slider::before,
    .klaro .cookie-notice .cm-list-input.only-required + .cm-list-label .slider::before {
      background-color: #171717;
      -webkit-transform: translateX(10px);
      -ms-transform: translateX(10px);
      transform: translateX(10px); }
  .klaro .cookie-modal .cm-list-input.required:checked + .cm-list-label .slider,
  .klaro .cookie-notice .cm-list-input.required:checked + .cm-list-label .slider {
    opacity: 0.8;
    background-color: #408cfc;
    cursor: not-allowed; }
  .klaro .cookie-modal .slider,
  .klaro .cookie-notice .slider {
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2), 5px 5px 10px 0 rgba(0, 0, 0, 0.19); }
  .klaro .cookie-modal .cm-list-input,
  .klaro .cookie-notice .cm-list-input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 50px;
    height: 30px; }
  .klaro .cookie-modal .cm-list-label,
  .klaro .cookie-notice .cm-list-label {
    /* The slider */
    /* Rounded sliders */ }
    .klaro .cookie-modal .cm-list-label .slider,
    .klaro .cookie-notice .cm-list-label .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      width: 50px;
      height: 30px;
      display: inline-block; }
    .klaro .cookie-modal .cm-list-label .slider::before,
    .klaro .cookie-notice .cm-list-label .slider::before {
      position: absolute;
      content: '';
      height: 20px;
      width: 20px;
      left: 5px;
      bottom: 5px;
      background-color: #fff;
      -webkit-transition: 0.4s;
      transition: 0.4s; }
    .klaro .cookie-modal .cm-list-label .slider.round,
    .klaro .cookie-notice .cm-list-label .slider.round {
      border-radius: 30px; }
    .klaro .cookie-modal .cm-list-label .slider.round::before,
    .klaro .cookie-notice .cm-list-label .slider.round::before {
      background-color: #171717;
      border-radius: 50%; }
    .klaro .cookie-modal .cm-list-label input:focus + .slider,
    .klaro .cookie-notice .cm-list-label input:focus + .slider {
      box-shadow: 0 0 1px #01440c; }
    .klaro .cookie-modal .cm-list-label input:checked + .slider::before,
    .klaro .cookie-notice .cm-list-label input:checked + .slider::before {
      -webkit-transform: translateX(20px);
      -ms-transform: translateX(20px);
      transform: translateX(20px); }
  .klaro .cookie-modal .cm-list-input:focus + .cm-list-label .slider,
  .klaro .cookie-notice .cm-list-input:focus + .cm-list-label .slider {
    box-shadow: 0 4px 6px 0 rgba(125, 125, 125, 0.2), 5px 5px 10px 0 rgba(125, 125, 125, 0.19); }
  .klaro .cookie-modal .cm-list-input:checked + .cm-list-label .slider::before,
  .klaro .cookie-notice .cm-list-input:checked + .cm-list-label .slider::before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px); }
  .klaro .cookie-modal .slider,
  .klaro .cookie-notice .slider {
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2), 5px 5px 10px 0 rgba(0, 0, 0, 0.19); }
  .klaro .cookie-modal a,
  .klaro .cookie-notice a {
    color: #999;
    text-decoration: none; }
  .klaro .cookie-modal p,
  .klaro .cookie-modal strong,
  .klaro .cookie-modal h1,
  .klaro .cookie-modal h2,
  .klaro .cookie-modal ul,
  .klaro .cookie-modal li,
  .klaro .cookie-notice p,
  .klaro .cookie-notice strong,
  .klaro .cookie-notice h1,
  .klaro .cookie-notice h2,
  .klaro .cookie-notice ul,
  .klaro .cookie-notice li {
    color: #eee; }
  .klaro .cookie-modal p,
  .klaro .cookie-modal h1,
  .klaro .cookie-modal h2,
  .klaro .cookie-modal ul,
  .klaro .cookie-modal li,
  .klaro .cookie-notice p,
  .klaro .cookie-notice h1,
  .klaro .cookie-notice h2,
  .klaro .cookie-notice ul,
  .klaro .cookie-notice li {
    display: block;
    text-align: left;
    margin: 0;
    padding: 0;
    margin-top: 0.7em; }
  .klaro .cookie-modal .cm-link,
  .klaro .cookie-notice .cm-link {
    margin-right: 0.5em;
    vertical-align: middle; }
  .klaro .cookie-modal .cm-btn,
  .klaro .cookie-notice .cm-btn {
    background: #555;
    color: #0D0D0D;
    border-radius: 0.3rem;
    margin-right: 0.5em;
    border: 0;
    padding: 0.5em 1em;
    font-size: 1em;
    cursor: pointer; }
    .klaro .cookie-modal .cm-btn:disabled,
    .klaro .cookie-notice .cm-btn:disabled {
      opacity: 0.5; }
    .klaro .cookie-modal .cm-btn.cm-btn-close,
    .klaro .cookie-notice .cm-btn.cm-btn-close {
      background: #eee;
      color: #000; }
    .klaro .cookie-modal .cm-btn.cm-btn-success,
    .klaro .cookie-notice .cm-btn.cm-btn-success {
      background: #00ffcd; }
    .klaro .cookie-modal .cm-btn.cm-btn-info,
    .klaro .cookie-notice .cm-btn.cm-btn-info {
      color: #fff;
      background: #363636; }

.klaro .cookie-modal {
  width: 100%;
  height: 100%;
  position: fixed;
  overflow: hidden;
  left: 0;
  top: 0;
  z-index: 1000; }
  .klaro .cookie-modal.cm-embedded {
    position: relative;
    height: inherit;
    width: inherit;
    left: inherit;
    right: inherit;
    z-index: 0; }
    .klaro .cookie-modal.cm-embedded .cm-modal {
      position: relative;
      transform: none; }
  .klaro .cookie-modal .cm-bg {
    background: rgba(0, 0, 0, 0.5);
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0; }
  .klaro .cookie-modal .cm-modal {
    z-index: 1001;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2), 5px 5px 10px 0 rgba(0, 0, 0, 0.19);
    width: 100%;
    max-height: 98%;
    top: 50%;
    transform: translateY(-50%);
    position: fixed;
    overflow: auto;
    background: #171717;
    color: #eee; }
    @media (min-width: 800px) {
      .klaro .cookie-modal .cm-modal {
        border-radius: 4px;
        position: relative;
        margin: 0 auto;
        max-width: 640px;
        height: auto;
        width: auto; } }
    .klaro .cookie-modal .cm-modal .hide {
      border-style: none;
      background: none;
      position: absolute;
      top: 20px;
      right: 20px;
      z-index: 1; }
      .klaro .cookie-modal .cm-modal .hide svg {
        stroke: #eee; }
    .klaro .cookie-modal .cm-modal .cm-footer {
      padding: 1em;
      border-top: 1px solid #555; }
      .klaro .cookie-modal .cm-modal .cm-footer-buttons {
        display: flex;
        flex-flow: row;
        justify-content: space-between; }
      .klaro .cookie-modal .cm-modal .cm-footer .cm-powered-by {
        font-size: 0.8em;
        padding-top: 4px;
        text-align: center; }
        .klaro .cookie-modal .cm-modal .cm-footer .cm-powered-by a {
          color: #999; }
    .klaro .cookie-modal .cm-modal .cm-header {
      padding: 1em;
      padding-right: 24px;
      border-bottom: 1px solid #555; }
      .klaro .cookie-modal .cm-modal .cm-header h1 {
        margin: 0;
        font-size: 2em;
        display: block; }
        .klaro .cookie-modal .cm-modal .cm-header h1.title {
          padding-right: 20px; }
    .klaro .cookie-modal .cm-modal .cm-body {
      padding: 1em; }
      .klaro .cookie-modal .cm-modal .cm-body ul {
        display: block; }
      .klaro .cookie-modal .cm-modal .cm-body span {
        display: inline-block;
        width: auto; }
      .klaro .cookie-modal .cm-modal .cm-body ul.cm-apps,
      .klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes {
        padding: 0;
        margin: 0; }
        .klaro .cookie-modal .cm-modal .cm-body ul.cm-apps li.cm-purpose .cm-apps .cm-caret,
        .klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose .cm-apps .cm-caret {
          color: #888; }
        .klaro .cookie-modal .cm-modal .cm-body ul.cm-apps li.cm-purpose .cm-apps .cm-content,
        .klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose .cm-apps .cm-content {
          margin-left: -40px;
          display: none; }
          .klaro .cookie-modal .cm-modal .cm-body ul.cm-apps li.cm-purpose .cm-apps .cm-content.expanded,
          .klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose .cm-apps .cm-content.expanded {
            margin-top: 10px;
            display: block; }
        .klaro .cookie-modal .cm-modal .cm-body ul.cm-apps li.cm-app,
        .klaro .cookie-modal .cm-modal .cm-body ul.cm-apps li.cm-purpose,
        .klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-app,
        .klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose {
          position: relative;
          line-height: 20px;
          vertical-align: middle;
          padding-left: 60px;
          min-height: 40px; }
          .klaro .cookie-modal .cm-modal .cm-body ul.cm-apps li.cm-app:first-child,
          .klaro .cookie-modal .cm-modal .cm-body ul.cm-apps li.cm-purpose:first-child,
          .klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-app:first-child,
          .klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose:first-child {
            margin-top: 0; }
          .klaro .cookie-modal .cm-modal .cm-body ul.cm-apps li.cm-app .switch,
          .klaro .cookie-modal .cm-modal .cm-body ul.cm-apps li.cm-purpose .switch,
          .klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-app .switch,
          .klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose .switch {
            position: absolute;
            left: 0; }
          .klaro .cookie-modal .cm-modal .cm-body ul.cm-apps li.cm-app p,
          .klaro .cookie-modal .cm-modal .cm-body ul.cm-apps li.cm-purpose p,
          .klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-app p,
          .klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose p {
            margin-top: 0;
            font-size: 14px; }
          .klaro .cookie-modal .cm-modal .cm-body ul.cm-apps li.cm-app p.purposes,
          .klaro .cookie-modal .cm-modal .cm-body ul.cm-apps li.cm-purpose p.purposes,
          .klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-app p.purposes,
          .klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose p.purposes {
            font-size: 0.8em;
            color: #999; }
          .klaro .cookie-modal .cm-modal .cm-body ul.cm-apps li.cm-app.cm-toggle-all,
          .klaro .cookie-modal .cm-modal .cm-body ul.cm-apps li.cm-purpose.cm-toggle-all,
          .klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-app.cm-toggle-all,
          .klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose.cm-toggle-all {
            border-top: 1px solid #555;
            padding-top: 1em; }
          .klaro .cookie-modal .cm-modal .cm-body ul.cm-apps li.cm-app span.cm-list-title,
          .klaro .cookie-modal .cm-modal .cm-body ul.cm-apps li.cm-purpose span.cm-list-title,
          .klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-app span.cm-list-title,
          .klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose span.cm-list-title {
            font-weight: 600; }
          .klaro .cookie-modal .cm-modal .cm-body ul.cm-apps li.cm-app span.cm-opt-out,
          .klaro .cookie-modal .cm-modal .cm-body ul.cm-apps li.cm-app span.cm-required,
          .klaro .cookie-modal .cm-modal .cm-body ul.cm-apps li.cm-purpose span.cm-opt-out,
          .klaro .cookie-modal .cm-modal .cm-body ul.cm-apps li.cm-purpose span.cm-required,
          .klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-app span.cm-opt-out,
          .klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-app span.cm-required,
          .klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose span.cm-opt-out,
          .klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose span.cm-required {
            padding-left: 0.2em;
            font-size: 0.8em;
            color: #999; }

.klaro .cookie-notice:not(.cookie-modal-notice) {
  background: #171717;
  z-index: 999;
  position: fixed;
  width: 100%;
  bottom: 0;
  right: 0; }
  @media (min-width: 1024px) {
    .klaro .cookie-notice:not(.cookie-modal-notice) {
      box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2), 5px 5px 10px 0 rgba(0, 0, 0, 0.19);
      border-radius: 0.3rem;
      position: fixed;
      bottom: 20px;
      right: 20px;
      max-width: 360px; } }
  @media (max-width: 1023px) {
    .klaro .cookie-notice:not(.cookie-modal-notice) {
      border-style: none;
      border-radius: 0; } }
  .klaro .cookie-notice:not(.cookie-modal-notice).cn-embedded {
    position: relative;
    height: inherit;
    width: inherit;
    left: inherit;
    right: inherit;
    bottom: inherit;
    z-index: 0; }
    .klaro .cookie-notice:not(.cookie-modal-notice).cn-embedded .cn-body {
      padding-top: 0.5em; }
  .klaro .cookie-notice:not(.cookie-modal-notice) .cn-body {
    margin-bottom: 0;
    margin-right: 0;
    bottom: 0;
    padding: .2em 1.2em 1.2em 1.2em; }
    .klaro .cookie-notice:not(.cookie-modal-notice) .cn-body p {
      margin-bottom: 0.5em; }
    .klaro .cookie-notice:not(.cookie-modal-notice) .cn-body p.cn-changes {
      text-decoration: underline; }
    .klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-learn-more {
      display: inline-block; }
    .klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-ok {
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      align-items: flex-end;
      margin-top: 1em; }

.klaro .cookie-modal-notice {
  z-index: 1001;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2), 5px 5px 10px 0 rgba(0, 0, 0, 0.19);
  width: 100%;
  max-height: 98%;
  top: 50%;
  transform: translateY(-50%);
  position: fixed;
  overflow: auto;
  background: #171717;
  color: #eee;
  padding: 1em;
  padding-top: 0.2em; }
  @media (min-width: 400px) {
    .klaro .cookie-modal-notice {
      border-radius: 4px;
      position: relative;
      margin: 0 auto;
      max-width: 400px;
      height: auto;
      width: auto; } }
  .klaro .cookie-modal-notice .cn-ok {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 1em; }

.klaro .cookie-notice-hidden {
  display: none !important; }

html {
  height: 100%;
  scroll-behavior: smooth; }

body {
  max-width: 2500px;
  margin: 0 auto;
  padding: 0;
  background: #0D0D0D;
  color: #fff; }
  body.menu-open {
    overflow-y: hidden; }

main {
  width: 100%; }

.container {
  padding-left: 1.5rem;
  padding-right: 1.5rem; }
  @media (min-width: 481px) {
    .container {
      padding-left: 2rem;
      padding-right: 2rem; } }
  @media (min-width: 760px) {
    .container {
      padding-left: 3rem;
      padding-right: 3rem; } }
  @media (min-width: 992px) {
    .container {
      padding-left: 4rem;
      padding-right: 4rem; } }
  @media (min-width: 1201px) {
    .container {
      padding-left: 6rem;
      padding-right: 6rem;
      max-width: calc(2500px - 12rem);
      margin-left: auto;
      margin-right: auto; } }
  .container:not(:first-of-type) {
    margin-top: 3rem;
    margin-bottom: 9rem; }
  .container--pusher {
    margin-bottom: 6rem; }
  @media (min-width: 760px) {
    .container--narrow {
      margin-left: auto;
      margin-right: auto;
      max-width: 55ch; } }
  .container__heading {
    margin-bottom: 1em;
    color: #fff; }
  @media (min-width: 760px) {
    .container h2 + .postcards {
      grid-template-columns: repeat(auto-fit, minmax(30ch, 1fr)); } }

.hidden {
  display: none; }

::selection {
  background: rgba(0, 255, 205, 0.99);
  color: #0D0D0D; }

.lost {
  margin: 0;
  padding: 12rem 0; }
  @media (min-width: 760px) {
    .lost {
      padding: 9rem 6rem; } }
  .lost__tagline {
    font-size: 1.455rem;
    line-height: 1.818rem;
    font-weight: 700;
    background: linear-gradient(90deg, #00FFCD 0.29%, #2A03FF 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent; }
    .lost__tagline:before {
      content: "—";
      margin-right: 0.25em; }
  .lost ul {
    text-align: left; }
    .lost ul a {
      color: #fff; }

input[type=text], [type=email], input[type=button], [type=submit] {
  -webkit-appearance: none; }
