.services {
  display: grid;
  grid-gap: 1rem;

  @include desktop {
    grid-template-columns: repeat(5, 1fr);
  }
}

.service {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2em;
  min-height: 12em;
  text-decoration: none;
  color: $foregroundColor;
  border-radius: $borderRadius;
  background: $backgroundGradient;

  &:hover {
    background: $primaryGradient;
    background-attachment: fixed;
  }

  @include desktop {
    &:nth-child(1) { grid-area: 1 / 1 / 2 / 4 }
    &:nth-child(2) { grid-area: 1 / 4 / 2 / 6 }
    &:nth-child(3) { grid-area: 2 / 1 / 3 / 3 }
    &:nth-child(4) { grid-area: 2 / 3 / 3 / 6 }
  }

  &__icon {
    align-self: flex-end;
    height: 3em;
    width: 3em;
    color: $primaryColor;
    // box-sizing: content-box;
    // border-radius: 9999px;
    // background-color: $primaryColor;
    // border: 10px solid $primaryColor;
    // background: $primaryGradient;
    // -webkit-background-clip: svg;
    // -webkit-text-fill-color: transparent;

    img {
      width: 100%;
    }
  }
}
