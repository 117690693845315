.footer {
  padding: 0 1em 8em;
  text-align: center;
  font-size: 1.2em;
  
  @include desktop {
    padding: $spacing $spacing 4em;
  }
  
  &__blocks {
    display: flex;
    flex-direction: column-reverse;
    
    @include desktop {
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }
  }

  &__block {
    margin-bottom: 2em;

    @include desktop {
      margin-bottom: 0;
    }
  }

  &__menu {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    list-style-type: none;
    font-weight: 600;
    
    @include desktop {
      text-align: left;
    }
  }

  &--column{
    margin: 2.5em 0;

    @include desktop {
      margin: 0 0 0 5%;
    }
  }

  &__item {
    margin: .5em 0;
    text-transform: lowercase;

    @include desktop {
      margin: .25em 0;
    }
  }

  &__link {
    margin: 0.5em 0;
    text-decoration: none;
    color: $foregroundColor;

    &:hover {
      text-decoration: underline;
    }
  }
}

.newsletter {
  margin-left: auto;
  align-self: flex-start;

  @include desktop {
    width: 35%;
    text-align: right;
  }

  &__title {
    margin-bottom: 1.25rem;
  }

  &__form {
    display: flex;
    position: relative;
    margin-bottom: .5rem; 
    padding: $borderThickness;
    border: $borderThickness $foregroundColor solid;
    border-radius: $round;
    box-sizing: border-box;
  }

  &__group {
    position: relative;
    width: 100%;
  }

  &__label {
    position: absolute;
    text-transform: lowercase;
    top: 0;
    bottom: 0;
    left: 0;
    right:0;
    transition: .2s;
    text-align: left;
    z-index: -1;
    padding: .5em 5em .5em 1.5em;
    font-size: .75em;
  }

  &__field {
    width: 100%;
    border-radius: $round;
    border: none;
    padding: .5em 5em .5em 1.5em;
    font-size: .75em;
    font-weight: 700;
    font-family: $primaryFont;
    color: $foregroundColor;
    background: transparent;
    box-sizing: border-box;

    &::placeholder {
      opacity: 0;
      transition: inherit;
    }

    &:focus {

      & + .newsletter__label {
        opacity: 0;
      }

      &::placeholder  {
        opacity: .5;
      }
    }

    &:valid {
      background: $backgroundColor;
    }
  }

  &__button {
    position: absolute;
    top: 3px;
    right: 3px;

    @include button($primaryColor, primary);
    padding-left: 1.5em;
    padding-right: 1.5em;
    padding-top: calc(.5em - 3px);
    padding-bottom: calc(.5em - 3px);
    font-size: .75em;
  }

  &__description {
    font-size: .75rem;
    color: $gray;
    font-weight: 700;

    > a {
      color: $gray;
    }
  }
}

.legal {
  margin-top: 5rem;
  color: $gray;
  
  @include desktop {
    flex-direction: row;
    justify-content: flex-end;

    .footer__link {
      margin-left: 1em;
    }
  }

  .footer__link {
    color: $gray;
  }
}
