.header {
  position: fixed;
  left: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 80px;
  z-index: 999;

  @include desktop {
    height: 120px;
  }

  &__logo {
    height: 40px;
    margin-right: auto;
    text-decoration: none;

    &:hover {
      img {
        transform: scale(-1, 1);
      }
    }

    img {
      height: 100%;
      transition: transform .5s ease-in-out;
    }
  }

  &__navigation {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    background-color: $primaryColor;
    overflow-y: auto;

    @include desktop {
      display: block;
      position: static;
      left: auto;
      top: auto;
      right: auto;
      bottom: auto;
      height: auto;
      background: none;
      overflow-y: visible;
    }

    &.open {
      display: block;
    }
  }

  &__links {
    font-size: 1.2rem;
    list-style-type: none;
    margin: 0;
    height: 100vh;
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 99;
    padding: 0;
    // display: none;
    /* this fixes the buggy scrolling on webkit browsers - mobile devices only - when overflow property is applied */
    // -webkit-overflow-scrolling: touch;
    // visibility: hidden;
    // opacity: 0;
    // transition: visibility 0s, opacity 0.3s;

    @include desktop {
      font-size: 1rem;
      flex-wrap: nowrap;
      flex-direction: row;
      align-items: center;
      height: auto;
      // visibility: visible;
      // opacity: 1;
    }
  }

  &__link {

    @include desktop {

      &:first-child {
        display: none;
      }
    }

    a {
      padding: 1.5em;
      display: block;
      color: $backgroundColor;
      text-decoration: none;
      text-transform: lowercase;

      @include desktop {
        padding: 0 1em;
        margin-left: 1em;
        color: $foregroundColor;

        &:hover {
          text-decoration: underline;
        }
      }

    }
  }

  &__cta {
    margin: 2.5em 0 0 0;

    a {
      @include button($backgroundColor, secondary);
    }

    @include desktop {
      margin: 0 0 0 1em;

      a {
        @include button($foregroundColor, primary);
      }
    }

  }

  &__trigger {
    // background: $primaryColor;
    height: 60px;
    width: 60px;
    z-index: 999;
    overflow: hidden;
    border-radius: $round;

    @include desktop {
      display: none;
    }

    .trigger-icon {
      width: 40px;
      height: 20px;
      position: relative;
      margin: 20px auto;
      transform: rotate(0deg);
      transition: .5s ease-in-out;
      cursor: pointer;

      span {
        display: block;
        position: absolute;
        height: 4px;
        width: 100%;
        // background: $backgroundColor;
        background: $foregroundColor;
        border-radius: $round;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: .25s ease-in-out;

        &:nth-child(1) {
          top: 0px;
        }

        &:nth-child(2), &:nth-child(3) {
          opacity: 0;
          top: 8px;
        }

        &:nth-child(4) {
          top: 16px;
        }
      }
    }

    &.open {
      background: $backgroundColor;

      span {
        background: $primaryColor;
      }

      span:nth-child(1) {
        top: 8px;
        width: 0%;
        left: 50%;
      }

      span:nth-child(2) {
        transform: rotate(45deg);
        opacity: 1;
      }

      span:nth-child(3) {
        transform: rotate(-45deg);
        opacity: 1;
      }

      span:nth-child(4) {
        top: 8px;
        width: 0%;
        left: 50%;
      }
    }
  }
}
/* Headroom-specific CSS */

.headroom {
  will-change: transform;
  transition: transform 200ms linear, background-color 200ms ease-in;

  &--pinned {
    transform: translateY(0%);
  }

  &--unpinned {
    transform: translateY(-100%);
  }

  &--top {
    background-color: transparentize($darkest-gray, 1);
  }

  &--not-top {
    background-color: transparentize($darkest-gray, 0.075);
    backdrop-filter: blur(.75em);
  }
}
