.focused {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: $spacing * 2 auto;
  text-align: center;

  &--small {
    margin-top: 0;
  }

  @include desktop {
    margin: $spacing * 1.5 $spacing * 2;

    &--small {
      margin-top: 0;
    }
  }

  &__heading {

  }

  &__text {
    margin-bottom: $spacing / 2;
  }

  &__image {
    padding: 2em;
    margin-bottom: $spacing / 2;

    @include tablet {
      max-width: 60%;
      padding: 0;
      // margin-bottom: 0;
    }

    img {
      width: 100%;
    }
  }
}
