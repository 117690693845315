.postcards {
  display: grid;
  grid-template-columns: repeat(auto-fit, 1fr);
  grid-gap: $spacing / 4;

  @include widescreen {
    // grid-template-columns: repeat(auto-fit, 1fr);
    // grid-template-columns: 1fr 1fr 1fr;
    grid-gap: $spacing / 2;
  }

  @include desktop {
    // grid-template-columns: 1fr 1fr;
    grid-gap: $spacing / 3;
    .postcard:first-child {
      // grid-area: 1 / 1 / 2 / 3;

      .postcard__thumbnail {
        // height: 300px;
      }
    }
  }

  @include tablet {
    grid-template-columns: repeat(auto-fill, minmax(30ch, 1fr))
  }
}

.postcard {
  box-sizing: border-box;
  border-radius: $borderRadius;
  text-decoration: none;
  background: $backgroundGradient;
  overflow: hidden;
  transition: transform .2s; /* Animation */

  &:hover {
    transform: scale(1.05);
  }

  &__description {
    padding: 1.4em 1.6em;
    color: $foregroundColor;

  }

  &__thumbnail {
    display: block;
    width: 100%;
    position: relative;
    height: 200px;
    overflow: hidden;
    background: $dark-gray;

    img {
      min-width: 100%;
      max-height: 100%;
      object-fit: cover;
    }
  }

  &__heading {
    margin-bottom: .4em;
    font-size: calc(1.424rem + 1.5 * ((100vw - 960px) / 480));

    a {
      display: block;
      color: $foregroundColor;
      word-wrap: break-word;
      text-decoration: none;
    }
  }

  &__summary {
    display: none;
      @include phone {
        display: block;
      }
  }

  &__meta {
    margin-bottom: .4em;

    time, span {
      color: darken($foregroundColor, 50);
    }

    a {
      color: $primaryColor;
      text-decoration: none;
    }
  }

  &__tags {
    justify-content: flex-start;
  }
}
