.checkbox {
    display: flex;
    cursor: pointer;
    position: relative;
    align-items: center;
}

.checkbox > input {
    height: 36px;
    width: 36px;
    margin: 0 1em 0 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    border: $borderThickness solid $foregroundColor;
    border-radius: 10px;
    outline: none;
    transition-duration: 0.3s;
    cursor: pointer;
  }

.checkbox > input:checked + ::before {
    content: '\2713';
    display: block;
    font-size: 24px;
    text-align: center;
    color: $foregroundColor;
    position: absolute;
    left: 6px;
    top: 6px;
}

.checkbox > input:active {
    border: $borderThickness solid $primaryColor;
}
