.pagehero {
  position: relative;
  text-align: center;
  padding: 15vmax 0;
  display: flex;
  overflow: hidden;

  &:before {
    content: " ";
    position: absolute;
    max-width: 100%;
    max-height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -10;
    background: $dark-gray;
  }

  @include tablet {
    text-align: left;
    padding: 25vh 0;
  }

  &__header {
    z-index: 10;

    @include tablet {
      width: 60vmax;
      position: relative;
    }
  }

  &__tagline {
    margin-bottom: 1em;
    font-size: 1.25rem;
    line-height: 1.818rem;
    font-weight: 700;
    background: $primaryGradient;
    background-clip: text;
    -webkit-text-fill-color: transparent;

    &:before {
      content: "—";
      margin-right: 0.25em;
    }
  }

  &__description {

    a {
      color: $foregroundColor;
    }
  }

  &__ctas {
    display: flex;
    flex-direction: column;
    margin: 1em 0 0;
    padding: 0;
    list-style-type: none;

    @include tablet {
      flex-direction: row;
    }

    li {
      margin: 0 0 1em 0;

      @include tablet {
        margin: 0 1em 0 0;
      }
    }
  }

  &__cta {
    display: block;

    &--primary {
      @include button($primaryColor, primary);
    }

    &--secondary {
      @include button($primaryColor, secondary);
    }
  }

  &__image {
    display: none;

    @include desktop {
      display: flex;
      justify-content: center;
      width: 40%;
      padding: 0 $spacing / 2;
      box-sizing: border-box;
    }
    img {
      width: 100%;
    }
  }

  &__separator {
    position: absolute;
    width: calc(100% + 10px);
    height: 100%;
    left: -5px;
    right: -5px;
    bottom: -2px;
    z-index: 5;
    background: url("/static/img/wave-2.svg") no-repeat center bottom;
    background-size: contain;
  }
}

#effect {
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -5;
  background: $dark-gray;
}
