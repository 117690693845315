// @import "icons";
@import "fonts";

@import "variables";
@import "mixins";
@import "animations";

@import "typography";

@import "components";

@import "klaro/klaro";

html {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  max-width: 2500px;
  margin: 0 auto;
  padding: 0;
  background: $backgroundColor;
  color: $foregroundColor;

  &.menu-open {
    overflow-y: hidden;
  }
}

main {
  width: 100%;
}

.container {
  padding-left: $spacing / 4;
  padding-right: $spacing / 4;
  // max-width: 100%;

  @include phone {
    padding-left: $spacing / 3;
    padding-right: $spacing / 3;
  }

  @include tablet {
    padding-left: $spacing / 2;
    padding-right: $spacing / 2;
  }

  @include desktop {
    padding-left: $spacing / 1.5;
    padding-right: $spacing / 1.5;
  }

  @include widescreen {
    padding-left: $spacing;
    padding-right: $spacing;
    max-width: calc(2500px - 12rem);
    margin-left: auto;
    margin-right: auto;
  }

  &:not(:first-of-type) {
    margin-top: $spacing / 2;
    margin-bottom: $spacing * 1.5;
  }

  &--pusher {
    margin-bottom: $spacing;
  }

  &--narrow {
    @include tablet {
      margin-left: auto;
      margin-right: auto;
      max-width: 55ch;
    }
  }

  &__heading {
    margin-bottom: 1em;
    color: $foregroundColor;
  }

  &__inner {
    // margin: $spacing 0;
  }

  h2 + .postcards {
    @include tablet {
      grid-template-columns: repeat(auto-fit, minmax(30ch, 1fr))
    }
  }
}

.hidden {
  display: none;
}

// Setting selection using rgba 99% to avoid Chrome overriding opacity

::selection {
  background: rgba(0, 255, 205, .99);
  color: $backgroundColor;
}

.lost {
  margin: 0;
  padding: $spacing * 2 0;

  @include tablet {
    padding: $spacing * 1.5 $spacing;
  }

  &__tagline {
    font-size: 1.455rem;
    line-height: 1.818rem;
    font-weight: 700;
    background: $primaryGradient;
    background-clip: text;
    -webkit-text-fill-color: transparent;

    &:before {
      content: "—";
      margin-right: 0.25em;
    }
  }

  ul {
    text-align: left;

    a {
      color: $foregroundColor;
    }
  }
}

input[type=text], [type=email], input[type=button], [type=submit] {
-webkit-appearance: none;
}
