.posthero {
  position: relative;
  text-align: center;
  padding: 25vh 0;
  overflow: hidden;
  // z-index: 1;
  // background: linear-gradient(96.34deg, #000000 0%, rgba(33, 2, 200, 0.21393) 78.61%, rgba(42, 3, 255, 0) 100%);


  @include tablet {
    text-align: left;
  }

  &__image {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -2;
    // background: linear-gradient(96.34deg,#000,rgba(0,0,0,.21393) 95.61%,rgba(0,0,0,.1));
  }

  &__header {
    @include tablet {
      max-width: 60vmax;
      z-index: 11;
    }
  }

  &__postmeta {
    margin-bottom: .5em;
    font-weight: 700;
    line-height: 1.818rem;
    // background: $primaryGradient;
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;

    &:before {
      content: "—";
      margin-right: 0.25em;
    }
  }

  &__summary {
    font-style: italic;

    a {
      color: $foregroundColor;
    }
  }

  &__separator {
    position: absolute;
    width: calc(100% + 10px);
    height: 100%;
    left: -5px;
    right: -5px;
    bottom: -2px;
    z-index: -1;
    background: url("/static/img/wave-1.svg") no-repeat center bottom;
    background-size: contain;
  }

  &__tags {
    justify-content: center;
    margin-bottom: 12em;

    @include tablet {
      justify-content: flex-start;
    }
  }
}
