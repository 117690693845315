.accordion {
  margin-bottom: $spacing;
  // padding: $spacing 0;

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

// Accordion

.accordion-item {
  margin-bottom: 1.75em;

  transition: height 1s ease-in-out;

  &__heading {
    // font-size: 2.25em;
    cursor: pointer;
    transition: padding 0.15s ease-in-out;

    &:before {
      margin-right: 1em;
      content: "+";
    }

    &:hover,
    &:active {
      padding-left: 1em;
    }
  }

  &__content {
    display: none;
    margin-bottom: 2.5em;

    @include widescreen {
      // padding: 0 35% 0 3.75em;
    }
  }

  &.open {
    .accordion-item__heading:before {
      content: "—";
    }

    .accordion-item__content {
      display: block;
    }
  }
}
