html {
  font-size: calc(20px + 2 * ((100vw - 960px) / 680));
  font-family: $primaryFont, sans-serif;
  font-variant-ligatures: no-common-ligatures;
  overflow-wrap: break-word;
  word-break: break-word;

  @include phone {
    overflow-wrap: normal;
    word-break: normal;
  }
}

@media screen and (min-width: 2500px) {
  html {
    font-size: 22px;
  }
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0 0 1em 0;
  font-weight: 700;
  text-transform: lowercase;
  line-height: 1.15;
}

h1 { font-size: calc(1.802rem + 10 * ((100vw - 640px) / 480)); margin-bottom: 1rem;}
h2 { font-size: calc(1.602rem + 8 * ((100vw - 640px) / 480)); }
h3 { font-size: calc(1.424rem + 4 * ((100vw - 640px) / 480)); }
h4 { font-size: calc(1.266rem + 1 * ((100vw - 640px) / 480)); }
h5 { font-size: calc(1.125rem + 0 * ((100vw - 640px) / 480)); }

p {
  font-weight: 500;
  line-height: 1.5;
  margin: 0 0 1em 0;

  @include tablet {
  }
}

code,
small,
.small {
  font-size: 0.85rem;
  font-weight: 500;
  line-height: 1.75;

  @include tablet {
  }
}

.break {

  @include tablet {
    display: none;
  }
}
