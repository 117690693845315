.paragraph {
  margin-bottom: $spacing;

  @include tablet {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
    text-align: left;

    &--reverse {
      flex-direction: row;
      text-align: right;
    }
  }

  &__content {
    box-sizing: border-box;

    @include tablet {
      max-width: 40%;
    }
  }

  &__heading {

  }

  &__text {

  }

  &__image {
    padding: 2em;
    margin-bottom: $spacing / 2;

    @include tablet {
      max-width: 45%;
      padding: 0;
      margin-bottom: 0;
    }

    img {
      width: 100%;
    }
  }
}
