/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 30px;
}

.cm-list-input:checked + .cm-list-label .slider {
    background-color: $primaryColor;
}

.cm-list-input.half-checked:checked + .cm-list-label .slider {
    opacity: 0.6;
    background-color: $primaryColor;

    &::before {
        background-color: $dark-gray;
        -webkit-transform: translateX(10px);
        -ms-transform: translateX(10px);
        transform: translateX(10px);
    }
}

.cm-list-input.only-required + .cm-list-label .slider {
    opacity: 0.8;
    background-color: $secondaryColor;

    &::before {
        background-color: $dark-gray;
        -webkit-transform: translateX(10px);
        -ms-transform: translateX(10px);
        transform: translateX(10px);
    }

}

.cm-list-input.required:checked + .cm-list-label .slider {
    opacity: 0.8;
    background-color: $secondaryColor;
    cursor: not-allowed;
}

.slider {
    box-shadow: $box-shadow;
}

.cm-list-input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 50px;
    height: 30px;
}

.cm-list-label {
    /* The slider */
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        width: 50px;
        height: 30px;
        display: inline-block;
    }

    .slider::before {
        position: absolute;
        content: '';
        height: 20px;
        width: 20px;
        left: 5px;
        bottom: 5px;
        background-color: #fff;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 30px;
    }

    .slider.round::before {
        background-color: $dark-gray;
        border-radius: 50%;
    }

    input:focus + .slider {
        box-shadow: 0 0 1px $green3;
    }

    input:checked + .slider::before {
        -webkit-transform: translateX(20px);
        -ms-transform: translateX(20px);
        transform: translateX(20px);
    }
}

.cm-list-input:focus + .cm-list-label .slider {
    box-shadow: 0 4px 6px 0 rgba(125, 125, 125, 0.2), 5px 5px 10px 0 rgba(125, 125, 125, 0.19);
}

.cm-list-input:checked + .cm-list-label .slider::before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
}
