.modal {
  display: none;

  &.is-open {
    display: block;
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: transparentize($backgroundColor, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(0.75em);
    z-index: 9999;

    &:active,
    &:focus {
      outline: 0;
    }

    @supports not (backdrop-filter: blur(0.5em)) {
      background: transparentize($backgroundColor, 0.025);
    }
  }

  &__container {
    position: relative;
    width: 100%;
    height: 100%;
    padding: $spacing;
    overflow-y: auto;
  }

  &__close {
    position: absolute;
    top: 7rem;
    right: $spacing / 4;
    color: $foregroundColor;
    font-weight: 900;
    text-decoration: none;
    font-size: 3em;

    @include phone {
      right: $spacing / 3;
    }

    @include tablet {
      right: $spacing / 2;
    }

    @include desktop {
      top: 2.5em;
      right: $spacing / 1.5;
    }

    @include widescreen {
      right: $spacing / 2;
    }

    &:after {
      content: "+";
      display: block;
      transform: rotateZ(45deg);
    }
  }

  &__content {
    overflow-y: auto;
    padding-top: $spacing/1.5;
    padding-bottom: $spacing/1.5;

    @include phone {
      padding-top: $spacing/2;
      padding-bottom: $spacing/2;
    }

    @include desktop {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-column-gap: 0px;
      grid-row-gap: 0px;
      grid-auto-rows: 1fr;
      padding-top: $spacing;
      padding-bottom: $spacing;
    }
  }
}

.contact-title {
  margin-bottom: 1em;

  @include desktop {
    grid-area: 1 / 2 / 2 / 3;
    text-align: right;
    margin-bottom: 0;
    padding-left: 3rem;
  }

  h2 {
    margin-bottom: 1em;
  }

  p {

    @include desktop {
      margin-left: 2em;
    }
  }
}

.form-panel {
  position: relative;

  @include desktop {
    grid-area: 1 / 1 / 3 / 2;
    border-right: 2px solid $foregroundColor;
    padding-right: 3rem;
  }
}

.contact-form {

  label {
    display: block;
    width: 100%;
    margin-bottom: 0.25em;
    text-transform: lowercase;

    @include desktop {
      font-size: 1.25em;
    }

    &.checkbox-label {
      display: inline;
      // font-size: 1.5rem;
      text-transform: none;

      @include desktop {
        font-size: 1em;
      }
    }
  }

  .form-group {
    margin-bottom: 1em;

    &.hidden {
      display: none;
    }
  }

  .field {
    display: block;
    box-sizing: border-box;
    width: 100%;
    // height: 5em;
    // font-size: 1.25em;
    border: 2px $foregroundColor solid;
    border-radius: $round;
    background: transparent;
    padding: 1em 2em;
    color: $foregroundColor;
    margin-bottom: 1em;
    font-family: 'Arial';

    @include desktop {
      font-size: .75em;
    }

    &:active,
    &:focus {
      outline: 0;
    }
  }

  .message {
    height: 10em;
    border-radius: 1rem;
    resize: vertical;
  }

  .form-check-input {
    margin-bottom: 1.5em;
  }

  .checkbox-label {
    cursor: pointer;
    margin-bottom: 0;

    a {
      color: $foregroundColor;
    }
  }

  .checkbox {
  }

  #submit {
    @include button($foregroundColor, primary);
  }
}

.contact-data {
  margin-top: 5em;
  
  @include desktop {
    margin-top: 0;
    grid-area: 2 / 2 / 3 / 3;
    display: flex;
    justify-content: flex-end;
  }
}

.submit-message {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  @include desktop {
    padding-right: 3rem;
  }
}
