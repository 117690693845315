.postcontrols {
  display: grid;
  grid-gap: 1rem;
  margin-top: $spacing;

  @include tablet {
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-content: center;
  }

  h3 {
    margin-bottom: 0;
  }

  .next {
  }

  .previous {
  }

  &__control {
    // background: $primaryGradient;
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
    color: $primaryColor;
  }
}
