.post {
  margin-top: $spacing;
  margin-bottom: $spacing;

  @include desktop {
    margin-top: $spacing / 2;
  }

  h2:not(:first-child) {
    margin-top: $spacing;
  }

  h3,
  h4,
  h5,
  h6 {
    margin-top: $spacing / 2;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    .header-anchor {
      position: absolute;
      font-size: 0.75em;
      padding-left: 0.5em;
      text-decoration: none;
      display: none;
    }

    &:hover {
      .header-anchor {
        display: inline;
        color: inherit;
        background: inherit;
      }
    }
  }

  ul,
  ol {
    padding: 0 0 0.5em 3rem;
    list-style-position: outside;

    li {
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      margin: 0.5em 0 0.5em 0;

      @include tablet {
      }
    }
  }

  a {
    color: $secondaryColor;
    text-decoration: none;
    @include transition-quick(.3s);

    &:hover {
      color: $foregroundColor;
      background: #004CBD;
    }
  }

  blockquote {
    font-style: italic;
    margin: 1.2em 0;
    padding: 0 1em 0 2em;
    display: flex;
    flex-direction: column;
    position: relative;
    font-size: 1.1rem;

    &:before {
      position: absolute;
      transform: translate(-2em, -0.5em);
      border-radius: .3em;
      width: .6em;
      height: 100%;
      white-space: pre;
      content: " ";
      background: $primaryColor;
    }
  }

  code {
    background: $dark-gray;
    padding: 0 .5em;
    border-radius: $borderRadiusSmall;
  }

  pre {
    display: block;
    background: $dark-gray;
    border-radius: $borderRadiusSmall;

    code {
      white-space: pre;
      padding: 1em;
      display: block;
      overflow-x: scroll;
      max-width: 100%;
      @include scrollbars(.6em, $gray, transparent);
    }
  }

  *:not(img) {
  }

  figure {
    background: $backgroundGradient;
    margin-left: 0;
    margin-right: 0;
    margin-top: $spacing / 2;
    margin-bottom: $spacing / 2;
    width: 100%;
    border-radius: $borderRadius;

    @include tablet {
      width: calc(100% + 2rem);
      margin-left: -1rem;
      margin-right: -1rem;
    }

    img {
      width: 100%;
      height: auto;
      border-radius: $borderRadius $borderRadius 0 0;
      padding: 0;
    }

    figcaption {
      padding: 1.4em 1.4em;
      font-size: .9rem;
    }
  }
}
