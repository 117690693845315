.sm-block {
  padding: 5rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url("/static/img/blob.svg") center no-repeat;
  background-size: contain;

  &__title {
    color: $darkest-gray;
    margin-bottom: .5rem;
  }

  &__icons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  &__item {
    display: flex;
    margin: .4rem;
    padding: .5rem;
    text-decoration: none;
    background: $darkest-gray;
    border-radius: $round;

     &:hover {
      .sm-block__img {
        // transform: rotateY(360deg);
        transform: scale(-1, 1);
        // margin-top: -0.5rem;
      }
    }
  }

  &__img {
    width: 1.25rem;
    height: 1.25rem;
    transition: transform .5s ease-in-out;
  }
}