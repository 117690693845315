.cta {
  margin: $spacing * 2 auto;
  text-align: center;

  @include tablet {
    margin: $spacing * 1.5 auto;
  }

  &__image {
    margin: 0 auto 2em;

    @include tablet {
      width: 60%;
    }

    @include desktop {
      width: 50%;
    }

    img {
      max-height: 80vh;
      width: 100%;
    }
  }

  &__title {
    margin-bottom: 0;
  }

  &__text {
    margin: .5em 10% 0;
  }

  &__action {
    margin: 1.5em auto 0;
  }

  &__field {
    width: 100%;
    max-width: 100%;
    border-radius: $round;
    border: $borderThickness solid $foregroundColor;
    background: $foregroundColor;
    padding: 0.5em 0.5em;
    font-size: 1rem;
    font-weight: 700;
    font-family: $primaryFont;
    margin: .25em 0;
    color: $backgroundColor;
    text-align: center;
    box-sizing: border-box;

    @include tablet {
    padding: 0.5em 2.25em;
    width: auto;
    }
  }

  &__button {
    @include button($primaryColor, primary);

    font-family: $primaryFont;
    margin: .25em;
  }

  &__subtext {
    display: block;
    margin: 2em 10% 0;

    @include tablet {
      margin: 1.5em 25% 0;
      font-size: .8em;
    }

    a {
      color: $foregroundColor;
    }
  }
}
